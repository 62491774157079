import { useEffect, useState } from "react";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { MintButton } from "./MintButton";
import { Timer } from "./Timer";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

import { AlertState, getAtaForMint } from "./utils";

import logo from "./images/WOS-logo.svg";
import bg from "./images/bg.jpg";

const Container = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
`;

const Navbar = styled.header`
  width: 100%;
  height: 120px;
  padding: 0 36px;
  display: flex;
  align-items: center;

  img {
    max-height: 40px;
    width: auto;
  }
`;

const Main = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 600;
  }

  button.crossmint-button {
    font-family: "Open Sans" !important;
    text-transform: uppercase;
  }
`;

const ConnectButton = styled(WalletDialogButton)`
  font-family: "Open Sans" !important;
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 700 !important;
  background-color: #923cff !important;
  letter-spacing: 0.06em !important;
  padding: 30px 48px !important;
  margin-top: 48px !important;
  margin-bottom: 24px !important;

  @media (min-width: 768px) {
    font-size: 32px !important;
  }

  @media (min-width: 992px) {
    font-size: 37px !important;
  }
`;

const Disconnected = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .text-container {
    padding: 24px 48px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.35);
  }

  p {
    font-family: "Open Sans";
    font-size: 22px;
  }

  @media (min-width: 768px) {
    p {
      font-size: 26px;
    }
  }
`;

const Connected = styled.div`
  margin-top: -120px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: "Open Sans";
    font-size: 48px;
    margin-top: 48px;
  }

  .text-container {
    padding: 24px 48px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.35);
  }

  p {
    font-family: "Open Sans";
    font-size: 22px;
  }

  @media (min-width: 768px) {
    p {
      font-size: 26px;
    }
  }
`;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [whitelistEnabled, setWhitelistEnabled] = useState(false);
  const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineStateF = () => {
    (async () => {
      const cndy = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      console.log(cndy);
      setCandyMachine(cndy);

      setItemsAvailable(cndy.state.itemsAvailable);
      setItemsRemaining(cndy.state.itemsRemaining);
      setItemsRedeemed(cndy.state.itemsRedeemed);
    })();
  };

  const refreshCandyMachineState = () => {
    (async () => {
      console.log("refresh cm");
      if (!wallet) return;

      const cndy = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      console.log(cndy);
      setCandyMachine(cndy);

      setItemsAvailable(cndy.state.itemsAvailable);
      setItemsRemaining(cndy.state.itemsRemaining);
      setItemsRedeemed(cndy.state.itemsRedeemed);

      // fetch whitelist token balance
      if (cndy.state.whitelistMintSettings) {
        setWhitelistEnabled(true);
        let balance = 0;
        try {
          const tokenBalance = await props.connection.getTokenAccountBalance(
            (
              await getAtaForMint(
                cndy.state.whitelistMintSettings.mint,
                wallet.publicKey
              )
            )[0]
          );

          balance = tokenBalance?.value?.uiAmount || 0;
        } catch (e) {
          console.error(e);
          balance = 0;
        }

        setWhitelistTokenBalance(balance);
      } else {
        setWhitelistEnabled(false);
      }
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      document.getElementById("#identity")?.click();
      if (wallet && candyMachine?.program && wallet.publicKey) {
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            "singleGossip",
            true
          );
        }

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction Timeout! Please try again.";
        } else if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshCandyMachineStateF, []);

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <Container>
      <Navbar>
        <img src={logo} alt="Fearless Bulls Club Logo" />
      </Navbar>
      <Main>
        {wallet ? (
          <Connected>
            <div className="text-container">
              <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
              <p>Balance: {(balance || 0).toLocaleString()} SOL</p>
              <p>Available: {itemsAvailable}</p>
              <p>Redeemed: {itemsRedeemed}</p>
              <p>Remaining: {itemsRemaining}</p>
              {whitelistEnabled && (
                <p>Whitelist token balance: {whitelistTokenBalance}</p>
              )}
            </div>
            {/* <h2>Pre-sale ends in:</h2> */}
            <MintButton
              candyMachine={candyMachine}
              isMinting={isMinting}
              onMint={onMint}
            />
            {/* <CrossmintPayButton
              collectionTitle="World of Solana"
              collectionDescription="Original NFT collection of 2,222 unique Solana blockchain heroines by famous female 2D artist BTG"
              collectionPhoto="https://www.worldofsolana.io/wp-content/uploads/2022/02/WOS_Splash_Fish_1920x1080.jpg"
              clientId="a9b2e40d-fde2-4894-ba22-265ae41aa9b5"
              className="crossmint-button"
            /> */}
          </Connected>
        ) : (
          <Disconnected>
            <div className="text-container">
              <p>Available: {itemsAvailable}</p>
              <p>Redeemed: {itemsRedeemed}</p>
              <p>Remaining: {itemsRemaining}</p>
            </div>
            <ConnectButton>CONNECT WALLET</ConnectButton>
            <CrossmintPayButton
              collectionTitle="World of Solana"
              collectionDescription="Original NFT collection of 2,222 unique Solana blockchain heroines by famous female 2D artist BTG"
              collectionPhoto="https://www.worldofsolana.io/wp-content/uploads/2022/02/WOS_Splash_Fish_1920x1080.jpg"
              clientId="a9b2e40d-fde2-4894-ba22-265ae41aa9b5"
              className="crossmint-button"
            />
            <Timer candyMachine={candyMachine} />
          </Disconnected>
        )}
      </Main>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Home;
