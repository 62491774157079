import styled from "styled-components";
import { CandyMachine } from "./candy-machine";
import Countdown from "react-countdown";
import { toDate } from "./utils";

const Container = styled.div`
  font-family: "Open Sans";
  font-size: 26px;
  margin: 36px 0 80px;
  text-align: center;
  text-shadow: rgba(114, 106, 106, 0.5) 0px 0px 5px,
    rgba(186, 175, 175, 0.5) 0px 0px 10px;

  h2 {
    font-family: "Open Sans";
    font-size: 48px;
  }

  @media (min-width: 480px) {
    font-size: 36px;
  }

  @media (min-width: 768px) {
    font-size: 54px;
  }
`;

export const CounterText = styled.span`
  .time {
    font-size: 52px;
  }

  @media (min-width: 480px) {
    .time {
      font-size: 72px;
    }
  }

  @media (min-width: 768px) {
    .time {
      font-size: 108px;
    }
  }
`; // add your styles here

export const Timer = ({
  candyMachine,
}: {
  candyMachine: CandyMachine | undefined;
}) => {
  return (
    <Container>
      {!candyMachine ? (
        "LOADING..."
      ) : (
        <Countdown
          // date={toDate(candyMachine?.state.goLiveDate)}
          date={1647878400000}
          renderer={renderCounter}
        />
      )}
    </Container>
  );
};

const renderCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <CounterText>
      <h2>Public Sale ends in:</h2>
      <span className="time">{hours + (days || 0) * 24}</span> Hours,{" "}
      <span className="time">{minutes}</span> Minutes,{" "}
      <span className="time">{seconds}</span> Seconds
    </CounterText>
  );
};
